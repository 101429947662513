.news-card-img-cont{
    width: 15rem;
}
.news-card-img{
    width: 100%;
}
.news-card-sub2{
    height: 8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.news-card-title{
    text-align: center;
    padding: 1rem 0.5rem;
}
.news-card-date{
    padding: 0 0 1rem 1rem;
    color: #A2A2A2;
}
.news-card-date .date{
    padding-left: 0.5rem;
}