.bg-gray{
    background-color: whitesmoke;
}
.footer-bar {
  background-color: var(--primary-cl);
  display: flex;
  justify-content: space-between;
  margin: 0 5%;
  padding: 0.6rem;
  border-top: 1px solid gray;
}
.flex2 {
  display: flex;
}
.flex2 p {
  padding: 0.4rem;
  font-size: 0.9rem;
}
.flex1 p {
  padding: 0.4rem;
  font-size: 0.9rem;
}
.footer-box a{
  text-decoration: none;
  color: white;
}
@media (max-width: 460px) {
  .footer-bar {
    flex-direction: column;
  }

  .flex1 p {
    /* font-size: 0.7rem; */
    text-align: center;
  }

  .flex2 p {
    flex-wrap: wrap;
    font-size: 0.6rem;
  }
}
/* ///////////////////////////////////////////////////////// */
.footer-logo {
  width: 9.5rem;
  margin: 2rem 0;
}
.logo-skill-india{
  width: 6rem;
  padding: 0.5rem;
  padding-left: 0;
  object-fit: contain;
}
.logo-nsdc{
  width: 9rem;
  padding: 1rem;
  object-fit: contain;
}
.logo-pstore{
object-fit: contain;
width: 8rem;
}
.logo-contn{
display: flex;

}
.sub-content-box-icons{
    margin-bottom: 2.8rem;
    width: 80%;
    display: flex;
    justify-content: flex-start;
    align-self: flex-start;
}
.fab{
    font-size: 1.2rem;
    padding: 1rem 1rem 0 1rem;
}
.fab-special{
    padding-left: 0 !important;
}
footer {
  background-color: var(--primary-cl);
  display: flex;
  padding: 2rem 1rem 1.5rem 3rem;
  background-color: whitesmoke;
  height: 100%;
  border-top: 1px solid gray;
}

.box-1 {
  width: 30%;
}
.box-2 {
  width: 23%;
}
.box-3 {
  width: 23%;
}
.box-4 {
  width: 23%;
}

.sub-content-box {
  margin: 2.5rem 0;
}
.sub-content-box p {
  padding: 0.5rem 0;
  font-size: 0.9rem;
}
.sub-content-box h5 {
  padding-bottom: 0.5rem;
  font-size: 1.1rem;
  font-weight: 550;
}
p a{
  text-decoration: none;
  color: white;
}
.footer-address{
  line-height: 1.5rem;
}

@media (max-width:1027px) {
  .box-1 {
    width: 100%;
  }
  .box-2 {
    width: 100%;
  }
  .box-3 {
    width: 100%;
  }
  .box-4 {
    width: 100%;
  }
  footer{
    display: flex;
    flex-wrap: wrap;
  }
}