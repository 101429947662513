/* .h-card{
    padding: 1rem 0;
} */

.h-card-sub{
    width: 80%;
    margin: auto;
    padding: 1rem 0;
}
.h-card-img{
    margin-bottom: 1rem;
    width: 100%;
}
.h-card-img-cont{
    width: 100%;
}
.h-card-img-cont{
    overflow: hidden;
    display: flex;
    justify-content: center;
}
.h-card p{
    font-size: 1.1rem;
    line-height: 1.5rem;
}
.h-card-title{
    font-size: 1.8rem;
    font-weight: 500;
    text-decoration: underline;
    margin-bottom: 0.5rem;
}
.h-card-subTitle{
    margin-bottom: 0.5rem;
    font-weight: 600;
}
.h-card-sub-cont{
    margin: 1.5rem 0;
}
.h-card-list{
    display: flex;
    flex-wrap: wrap;
}
.h-card-list li{
    width: 33%;
    font-size: 1.1rem;
    line-height: 1.5rem;
}
@media (max-width:450px) {
    .h-card-list li{
        width: 80%;
        font-size: 1.1rem;
        line-height: 1.5rem;
    }   
}
@media (min-width:451px) and (max-width:800px) {
    .h-card-list li{
        width: 50%;
        font-size: 1.1rem;
        line-height: 1.5rem;
    }   
}

