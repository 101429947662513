.wide-text-card{
    box-shadow: #00000040 0px 8px 25px;
    border-radius: 20px;
    padding: 3rem ;
    box-sizing: border-box;
}
.cont-sub-com-prof{
    margin-bottom: 4rem;
}
.wc-title{
    margin: 1rem 0;
}