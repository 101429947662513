.car-img-cont{
    display: flex;
}
.car-img{
    height: 20rem;
    margin: 0.6rem;
}
@media (max-width:400px){
    .car-img{
        height: 15rem;
        margin: 0.6rem;
    }   
}