.home {
  width: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bg-home{
  background-image: url("https://res.cloudinary.com/learnhat/image/upload/v1640675834/orion-new-website/Banner_i3ugin.png");
  height: 90vh;
}
.home-data-cont{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.home-data-subcont-right{
  color: white;
  width: 35%;
  padding: 5rem;
}
.hero-title-1{
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: 500;
}
.hero-title-2{
  font-size: 3.5rem;
  line-height: 3.8rem;
  font-weight: 500;
}
.inner-cont{
  margin-bottom: 2.5rem;
}
@media (max-width: 1100px) {
  .home-data-subcont-right{
    padding: 2.5rem;
  }
  .hero-title-1{
    font-size: 1.7rem;
    line-height: 2.1rem;
    font-weight: 500;
  }
  .hero-title-2{
    font-size: 3.2rem;
    line-height: 3.5rem;
    font-weight: 500;
  }
  
}
@media (max-width: 500px) {
  .home {
    height: 40vh;
    /* background-size: 100% 100%; */
    background-repeat: no-repeat;
    background-position: 0% 20%;
    background-position: center;
  }
  .home-data-subcont-right{
    padding: 0.4rem;
    width: 45%;
  }
  .hero-title-1{
    font-size: 0.9rem;
    line-height: 1rem;
    font-weight: 500;
  }
  .hero-title-2{
    font-size: 1.2rem;
    line-height: 1.5rem;
    font-weight: 500;
  }
}
@media screen and (min-width: 500px) and (max-width: 850px){
  .home {
    height: 65vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .home-data-subcont-right{
    padding: 2rem;
    width: 38%;
  }
  .hero-title-1{
    font-size: 1.5rem;
    line-height: 1.9rem;
    font-weight: 500;
  }
  .hero-title-2{
    font-size: 2.6rem;
    line-height: 3.3rem;
    font-weight: 500;
  }
}
