@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Josefin Sans", sans-serif;
}
:root {
  --primary-cl: #0031ba;
  --hover-cl: #ffaa46;
  --section-title-cl: #2f4152;
}
/* 1 */
.bg-whsmoke {
  background-color: whitesmoke;
}
.section-container {
  padding: 4rem 0;
}
.section-title-cont {
  display: flex;
  align-items: center;
  margin-bottom: 1.8rem;
}
.section-title-line {
  width: 10rem;
  height: 4px;
  background-color: #b7b7b7;
  border: 0;
  margin-right: 1.5rem;
}
.section-title {
  font-size: 2rem;
  font-weight: 600;
  text-align: left;
  color: #2f4152;
  color: var(--section-title-cl);
  max-width: 55%;
}
.p-text {
  color: #596875;
  font-size: 1rem;
  line-height: 1.2rem;
}
.card-sub-title {
  color: #2f4152;
  font-size: 1.2rem;
  line-height: 1.4rem;
}
.card-holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 85%;
  margin: auto;
  /* background-color: yellow; */
}
.card-holder-full {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  /* background-color: yellow; */
}
.card {
  width: 15rem;
  margin: 0.6rem;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: #00000040 0 8px 25px;
}
.card-landscape {
  width: 30rem;
  margin: 0.6rem;
  overflow: hidden;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: #00000040 0 8px 25px;
  /* height: 17rem; */
}
.card-bg-gray {
  background-color: #e8e8e8;
}
.bg-gray-lite{
  background-color: #FAFAFA;
}
.bg-apna-blue{
  background-color: #0031ba;
  background-color: var(--primary-cl);
  color: white;
}
@media (max-width: 580px) {
  .card-landscape {
    width: 17rem;
    margin: 0.6rem;
  }
  .section-title-line {
    width: 6rem;
  }
}

/* 2 */
.page-title {
  border-bottom: 1px solid black;
  margin-bottom: 1rem;
}
.page-title h1 {
  padding: 0.5rem;
}

.one-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  margin: auto;
  padding: 3rem 0;
}

.one-view-text-box {
  font-size: 1.1rem;
  line-height: 1.5rem;
  color: rgb(24, 23, 23);
  margin: 1rem;
}
.full-img{
  max-width: 100%;
  max-height: 100%;
}

/* //Utility */
.cl-white {
  color: white !important;
}
.bg-whsmoke {
  background-color: whitesmoke;
}
.bg-apna-orange {
  background-color: #fa8128;
}
.cl-orng-text{
  color: #FFAA46;
}
.bg-white{
  background-color: white;
}


.know-more-a{
  text-decoration: none;
  display: flex;
  text-align: left;
}
nav{
    height: 6rem;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 6rem;
    border-bottom: 2px solid whitesmoke;
    width: 75%;
    margin: auto;
}
.nav-cont{
    height:50px;   
}

.nav-items-cont{
    width: 70%;
}

.nav-items-list{
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.nav-item{
    padding: 13px 5px;
    margin: 5px;
    font-size: 0.9rem;

}

.nav-item a{
    text-decoration: none;
    color: #000;
    
}
.nav-btn{
    padding: 5px;
    margin: 5px;
    background-color: #FA8128;
}

.menu-btn{
    border: none;
    outline: none;
    background-color: transparent;
    color: #000;
    display: none;
    font-size: 1.2rem;
}

@media(max-width: 500px){
    nav{
        padding: 0 1rem;
    }
    .nav-items-cont{
        display: none;
    }
    .menu-btn{
        display: inline;
        display: initial;
    }
}
@media screen and (min-width: 500px) and (max-width: 850px){
    .tab-hide{
        display: none;
    }
    nav{
        padding: 0 1rem;
    }
}

/* DisplayHoverItem */
.sub-menu{
    position: absolute;
    top: 70px;
    background-color: white;
    display: flex;
    flex-direction: column;
    display: none;
    z-index: 99;
}
.active{
    display: flex !important;
}
.sub-menu a{
    text-decoration: none;
    color: black;
}
.sub-menu-item{
    padding: 5px;
    margin:  5px 13px;
    
}
.sub-menu-item-bold{
    padding: 5px;
    margin:  5px 13px;
    font-weight: bold;
}
.button {
  border-radius: 10px;
  font-weight: 400;
  padding: 0.5rem 1.5rem;
  outline: none;
  border: none;
  cursor: pointer;
  min-width: 7.6rem;
  height: 2.3rem;
  font-size: 1rem;
}
.login-btn {
  font-weight: bold;
}
.large-btn {
  width: auto;
}
.orange {
  color: #fff;
  background-color: var(--primary-cl);
}
.orange:hover {
  background-color: var(--hover-cl);
}

.white {
  color: var(--primary-cl);
  background-color: #fff;
  border: 1px solid var(--primary-cl);
}
.white:hover {
  color: var(--hover-cl);
  border: 1px solid var(--hover-cl);
}

@media (max-width: 460px) {
  .button {
    width: 7rem;
  }
}

/* Know more */
.kmb-cont {
  color: var(--primary-cl) !important;
  margin: auto;
}
.kmb-cont:hover {
  color: var(--hover-cl);
}
.button-know-more {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: block;
  font-size: 1rem;
  color: var(--primary-cl) !important;
}
.button-know-more:hover {
  color: var(--hover-cl) !important;
}
.know-more-line-short {
  display: inline-block;
  width: 3rem;
  transition: 0.5s;
  background-color: var(--primary-cl) ;
  height: 1px;
  border: none;
}
.know-more-line-long {
  display: inline-block;
  width: 6rem;
  transition: 0.5s;
  background-color: var(--hover-cl) !important;
  height: 1px;
  border: none;
}

.mobile-nav{
    width: 100%;
    height: 100vh;
    background-color: #fff;
    position: absolute;
    top: 5rem;
    left: -120%;
    transition: ease-in 0.2s;
    z-index: 999;
}

.mobile-nav.active{
    left: 0;
}

.mobile-nav-items{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem 3rem;
    
}

.mobile-nav-items-list{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

ul{
    list-style: none;
}
.mobile-nav-items-list li{
    font-size: 1.3rem;
    font-weight: 500;
}
.nav-item-icon-cont{
    display: flex;
    justify-content: space-between;
}
.nav-item-m{
    padding: 6px 5px;
    margin: 5px;
    font-size: 0.9rem;
}
.nav-item-m a{
    text-decoration: none;
    color: #000;
}

/* pp */
.mob-sub-menu{
    background-color: white;
    display: flex;
    flex-direction: column;
    display: none;
    z-index: 99;
}
.mob-active{
    display: flex !important;
}
.mob-sub-menu a{
    text-decoration: none;
    color: black;
    font-size: 1.1rem;
}
.mob-sub-menu-item{
    padding: 5px;
    margin:  5px 13px;   
}
.mob-sub-menu-item-bold{
    padding: 5px;
    margin:  5px 13px;
    font-weight: bold;
}
.bg-gray{
    background-color: whitesmoke;
}
.footer-bar {
  background-color: var(--primary-cl);
  display: flex;
  justify-content: space-between;
  margin: 0 5%;
  padding: 0.6rem;
  border-top: 1px solid gray;
}
.flex2 {
  display: flex;
}
.flex2 p {
  padding: 0.4rem;
  font-size: 0.9rem;
}
.flex1 p {
  padding: 0.4rem;
  font-size: 0.9rem;
}
.footer-box a{
  text-decoration: none;
  color: white;
}
@media (max-width: 460px) {
  .footer-bar {
    flex-direction: column;
  }

  .flex1 p {
    /* font-size: 0.7rem; */
    text-align: center;
  }

  .flex2 p {
    flex-wrap: wrap;
    font-size: 0.6rem;
  }
}
/* ///////////////////////////////////////////////////////// */
.footer-logo {
  width: 9.5rem;
  margin: 2rem 0;
}
.logo-skill-india{
  width: 6rem;
  padding: 0.5rem;
  padding-left: 0;
  object-fit: contain;
}
.logo-nsdc{
  width: 9rem;
  padding: 1rem;
  object-fit: contain;
}
.logo-pstore{
object-fit: contain;
width: 8rem;
}
.logo-contn{
display: flex;

}
.sub-content-box-icons{
    margin-bottom: 2.8rem;
    width: 80%;
    display: flex;
    justify-content: flex-start;
    align-self: flex-start;
}
.fab{
    font-size: 1.2rem;
    padding: 1rem 1rem 0 1rem;
}
.fab-special{
    padding-left: 0 !important;
}
footer {
  background-color: var(--primary-cl);
  display: flex;
  padding: 2rem 1rem 1.5rem 3rem;
  background-color: whitesmoke;
  height: 100%;
  border-top: 1px solid gray;
}

.box-1 {
  width: 30%;
}
.box-2 {
  width: 23%;
}
.box-3 {
  width: 23%;
}
.box-4 {
  width: 23%;
}

.sub-content-box {
  margin: 2.5rem 0;
}
.sub-content-box p {
  padding: 0.5rem 0;
  font-size: 0.9rem;
}
.sub-content-box h5 {
  padding-bottom: 0.5rem;
  font-size: 1.1rem;
  font-weight: 550;
}
p a{
  text-decoration: none;
  color: white;
}
.footer-address{
  line-height: 1.5rem;
}

@media (max-width:1027px) {
  .box-1 {
    width: 100%;
  }
  .box-2 {
    width: 100%;
  }
  .box-3 {
    width: 100%;
  }
  .box-4 {
    width: 100%;
  }
  footer{
    display: flex;
    flex-wrap: wrap;
  }
}
.car-img-cont{
    display: flex;
}
.car-img{
    height: 20rem;
    margin: 0.6rem;
}
@media (max-width:400px){
    .car-img{
        height: 15rem;
        margin: 0.6rem;
    }   
}
.home {
  width: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bg-home{
  background-image: url("https://res.cloudinary.com/learnhat/image/upload/v1640675834/orion-new-website/Banner_i3ugin.png");
  height: 90vh;
}
.home-data-cont{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.home-data-subcont-right{
  color: white;
  width: 35%;
  padding: 5rem;
}
.hero-title-1{
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: 500;
}
.hero-title-2{
  font-size: 3.5rem;
  line-height: 3.8rem;
  font-weight: 500;
}
.inner-cont{
  margin-bottom: 2.5rem;
}
@media (max-width: 1100px) {
  .home-data-subcont-right{
    padding: 2.5rem;
  }
  .hero-title-1{
    font-size: 1.7rem;
    line-height: 2.1rem;
    font-weight: 500;
  }
  .hero-title-2{
    font-size: 3.2rem;
    line-height: 3.5rem;
    font-weight: 500;
  }
  
}
@media (max-width: 500px) {
  .home {
    height: 40vh;
    /* background-size: 100% 100%; */
    background-repeat: no-repeat;
    background-position: 0% 20%;
    background-position: center;
  }
  .home-data-subcont-right{
    padding: 0.4rem;
    width: 45%;
  }
  .hero-title-1{
    font-size: 0.9rem;
    line-height: 1rem;
    font-weight: 500;
  }
  .hero-title-2{
    font-size: 1.2rem;
    line-height: 1.5rem;
    font-weight: 500;
  }
}
@media screen and (min-width: 500px) and (max-width: 850px){
  .home {
    height: 65vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .home-data-subcont-right{
    padding: 2rem;
    width: 38%;
  }
  .hero-title-1{
    font-size: 1.5rem;
    line-height: 1.9rem;
    font-weight: 500;
  }
  .hero-title-2{
    font-size: 2.6rem;
    line-height: 3.3rem;
    font-weight: 500;
  }
}

.sub-cont {
  margin: 0.6rem;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 185px;
  height: 170px;
}
.item {
  width: 135px;
  /* width: 100px; */
    -webkit-filter: grayscale(100%) ;
            filter: grayscale(100%) ;
    opacity: 0.8;
}

@media (max-width: 500px){
  .card-holder-clients{
    width: 100%;
  }
  .sub-cont{
    height: 120px;
    width: 120px;
  }
  .item {
    width: 90px;
  }
}



.company-text-box{
  max-width: 60%;
  text-align: center;
}
.comp-text{
  margin: 1rem 0;
}
.wide-text-card{
    box-shadow: #00000040 0px 8px 25px;
    border-radius: 20px;
    padding: 3rem ;
    box-sizing: border-box;
}
.cont-sub-com-prof{
    margin-bottom: 4rem;
}
.wc-title{
    margin: 1rem 0;
}
.focus-area-card{
    border-radius: 20px;
    box-shadow: #00000040 0 8px 25px ;
    display: flex;
}
.focus-area-sub-cont1{
    width: 50%;
    height: 100%;
    overflow: hidden;
}
.focus-area-img{
    height: 100%;
    width: auto;
}
.focus-area-sub-cont2{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 1.5rem 0;
}
.fac-sd1{  
    padding: 0 2rem 0 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.width60p{
    height: 60%;
}
.fac-sd2{
    height: 30%;
    margin: 0 2rem;
}


@media (max-width:600px){
    .focus-area-card{
        flex-direction: column;
        height: 100% !important;
    }
    .focus-area-sub-cont1, .focus-area-sub-cont2{
        width: auto;
        overflow: visible;
    }
    .focus-area-img{
        height: 15rem;
        text-align: center;
        width: auto;
        border-radius: 20px;
    }
    .fac-sd1{
        margin: 1rem 0;
    }
}


.testimonial-card-outer{
    margin: 3rem 0;
}
.testimonial-card{
    padding: 2rem 2.5rem;
    box-sizing: border-box;
}

.avatar-img{
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    border: 1px solid gray;
    /* padding: 0.2rem; */
    margin-right: 1rem;
}
.quote-cont{
    height: 4rem;
    min-width: 4rem;
    margin-right: 1rem;
    /* text-align: center; */
    display: flex;
    justify-content: center;
}
.t-card-p1{
    display: flex;
    padding-bottom: 1rem;
    text-align: left;

}
.t-card-p1-sub1 h5{
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
}
.t-card-p1-sub1 p{
    font-size: 0.8rem;
    margin-bottom: 0.2rem;
}
.t-card-p2{
    /* padding: 1rem; */
    height: 8rem;
    display: flex;
}
.fa-quote-left{
    font-size: 2.5rem;
    color: #FA8128;
}
.t-card-p2 .desc{
    font-size: 0.9rem;
    /* padding: 1rem; */
    text-align: left;
}
.t-card-p2 .descShort{
    padding: 6rem 2rem;
    text-align: center;
}
.read-more{
    text-align: center;
    color: #FA8128;
    cursor: pointer;
}

.testimonial-card-outer h2{
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    margin: 1rem;
}

.testimonial-stars{
    width: 10rem;

}

@media (max-width:400px){
    .testimonial-card{
        padding: 1rem;
        box-sizing: border-box;
    }
    .t-card-p2{
        height: 17rem;
    }
    
}


.news-card-img-cont{
    width: 15rem;
}
.news-card-img{
    width: 100%;
}
.news-card-sub2{
    height: 8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.news-card-title{
    text-align: center;
    padding: 1rem 0.5rem;
}
.news-card-date{
    padding: 0 0 1rem 1rem;
    color: #A2A2A2;
}
.news-card-date .date{
    padding-left: 0.5rem;
}


.key-sec-images-cont {
  display: flex;
  flex-direction: column;
}

.small-img {
  width: 15rem;
  margin: 0.3rem;
  height: 9rem;
  background-size: contain;
}
.large-img {
  width: 30.6rem;
  margin: 0.3rem;
  height: 20rem;
  background-size: contain;
}
img{
    object-fit: cover;
}
@media (max-width: 500px) {
  .small-img {
    width: 15rem;
    margin: 0.3rem;
    height: auto;
  }
  .large-img {
    width: 15rem;
    margin: 0.3rem;
    height: auto;
  }
  .key-sec-images-cont{
    
    width: -webkit-min-content;
    
    width: min-content;
  }
}

.sub-title {
  font-weight: bold;
  color: white;
  padding: 0.2rem 0.3rem;
  margin: 0.2rem;
}
.mission-vision-box{
  background-color: #FA8128;

}

/* Awards and recognition */
.awardsCardHolder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}


/* Ledaership */
.leadership-sub-title{
  padding: 1rem 1rem 0 1rem;
  font-size: 2rem;
}
.notext-awards-card {
    background-color: rgb(228, 225, 225);
    margin: 0.6rem;
  }
  .notext-award-img {
    height: 17rem;
    width: 100%;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 1rem 0;
  }
  .notext-award-img-team {
    height: 15rem;
    width: 100%;
    overflow: hidden;
  }
  .notext-award-img-self{
    width: 100%;
    margin: 0 !important;
  }
  .notext-awards-card .notext-awards-card-desc {
    padding: 1rem;
    line-height: 1.3rem;
    font-size: 1rem;
    align-content: center;
    align-items: center;
  }
  .notext-name-post{
    text-align: center;
    margin-top: 0.5rem;
  }
  .notext-name-post-h5{
    font-size: 1.2rem;
    margin-bottom: 0.3rem;
  }
  .notext-name-post-p{
    font-size: 0.8rem;
  }
  @media (max-width: 435px) {
    .notext-awards-card {
      width: 17rem !important;
      height: auto !important;
    }
    .notext-awards-card img {
      height: 17rem !important;
      width: 17rem !important;
    }
  }
  



.contactus-sub-cont{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    background-color: whitesmoke;
    margin: 0.6rem auto;
}

.contactus-boxs{
    margin: 2rem;
}
.contactus-boxs h3{
    font-size: 1.5rem;
    font-weight: 550;
    margin-bottom: 0.5rem;
}
.contactus-boxs p{
    font-size: 1.1rem;
    line-height: 1.5rem;
}
.contactus-map{
    width: 100%;
}


.no-bottom-pd{
    padding-bottom: 0 !important;
}
/* .h-card{
    padding: 1rem 0;
} */

.h-card-sub{
    width: 80%;
    margin: auto;
    padding: 1rem 0;
}
.h-card-img{
    margin-bottom: 1rem;
    width: 100%;
}
.h-card-img-cont{
    width: 100%;
}
.h-card-img-cont{
    overflow: hidden;
    display: flex;
    justify-content: center;
}
.h-card p{
    font-size: 1.1rem;
    line-height: 1.5rem;
}
.h-card-title{
    font-size: 1.8rem;
    font-weight: 500;
    text-decoration: underline;
    margin-bottom: 0.5rem;
}
.h-card-subTitle{
    margin-bottom: 0.5rem;
    font-weight: 600;
}
.h-card-sub-cont{
    margin: 1.5rem 0;
}
.h-card-list{
    display: flex;
    flex-wrap: wrap;
}
.h-card-list li{
    width: 33%;
    font-size: 1.1rem;
    line-height: 1.5rem;
}
@media (max-width:450px) {
    .h-card-list li{
        width: 80%;
        font-size: 1.1rem;
        line-height: 1.5rem;
    }   
}
@media (min-width:451px) and (max-width:800px) {
    .h-card-list li{
        width: 50%;
        font-size: 1.1rem;
        line-height: 1.5rem;
    }   
}


