.notext-awards-card {
    background-color: rgb(228, 225, 225);
    margin: 0.6rem;
  }
  .notext-award-img {
    height: 17rem;
    width: 100%;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 1rem 0;
  }
  .notext-award-img-team {
    height: 15rem;
    width: 100%;
    overflow: hidden;
  }
  .notext-award-img-self{
    width: 100%;
    margin: 0 !important;
  }
  .notext-awards-card .notext-awards-card-desc {
    padding: 1rem;
    line-height: 1.3rem;
    font-size: 1rem;
    align-content: center;
    align-items: center;
  }
  .notext-name-post{
    text-align: center;
    margin-top: 0.5rem;
  }
  .notext-name-post-h5{
    font-size: 1.2rem;
    margin-bottom: 0.3rem;
  }
  .notext-name-post-p{
    font-size: 0.8rem;
  }
  @media (max-width: 435px) {
    .notext-awards-card {
      width: 17rem !important;
      height: auto !important;
    }
    .notext-awards-card img {
      height: 17rem !important;
      width: 17rem !important;
    }
  }
  